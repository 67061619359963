:root{
  --lapis-lazuli: #335c81;
  --french-gray: #c7cedb;
  --cadet-gray: #a0aab2;
  --dark-purple: #2b061e;
  --rose-taupe: #875053;
}

html {
  color: var(--french-gray);

  font-size: 1.2rem;
  line-height: 1.4;
  font-family: system,-apple-system,".SFNSText-Regular","San Francisco",Roboto,"Segoe UI","Helvetica Neue","Lucida Grande",sans-serif;
  text-align: center;
  margin: 0;
}

body {
  background: rgb(13,13,13);
  background: linear-gradient(90deg, rgba(13,13,13,1) 0%, rgba(31,17,26,1) 5%, rgba(43,6,30,1) 50%, rgba(31,17,26,1) 95%, rgba(13,13,13,1) 100%);
  background-attachment: fixed;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  font-family: 'Libre Baskerville', serif;
  font-size: 1em;
}

.header {
  /*background: var(--french-gray);*/
  /*background: radial-gradient(circle, var(--french-gray) 0%, var(--cadet-gray) 90%);*/
  display: flex;
  padding: 1em;
}

.logobox {
  width: 100%;
  margin: auto;
  padding-inline: 2em;
  background: white;
  background: var(--french-gray);
  background: radial-gradient(circle, white 0%, var(--french-gray) 80%);
  border-radius: 1em;
  /*margin: 1em auto;*/
}

.content {
  display: flex;
  flex-direction: column;
}

article {
  padding: 2em;
  max-width: 600px;
  margin: auto;
  /*display: inline-flex;*/
  &.wider {
    max-width: 1024px;
  }
}


.content-image {
  margin: auto;
}

.content-text {
  padding: 1em;
  margin: auto;
}


.footer {
  padding: 1em;
  font-size: 0.7em;
  margin-top: auto;
}

.cls-1 {
  fill: var(--french-gray);
}

.cls-2 {
  fill: var(--lapis-lazuli);
}

.cls-3 {
  fill: var(--cadet-gray);
}


h1 {
  font-family: 'Fjalla One', sans-serif;
  font-size: 2em;
}

h2 {
  font-family: 'Fjalla One', sans-serif;
  font-size: 2em;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

/*
 * A better looking default horizontal rule
 */

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
  resize: vertical;
}

/* ==========================================================================
   Author's custom styles
   ========================================================================== */

/* ==========================================================================
   Helper classes
   ========================================================================== */

/*
 * Hide visually and from screen readers
 */

.hidden,
[hidden] {
  display: none !important;
}

/*
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 */

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  /* 1 */
}

/*
 * Extends the .sr-only class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.sr-only.focusable:active,
.sr-only.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  white-space: inherit;
  width: auto;
}

/*
 * Hide visually and from screen readers, but maintain layout
 */

.invisible {
  visibility: hidden;
}

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

.clearfix::before,
.clearfix::after {
  content: " ";
  display: table;
}

.clearfix::after {
  clear: both;
}

/* ==========================================================================
   EXAMPLE Media Queries for Responsive Design.
   These examples override the primary ('mobile first') styles.
   Modify as content requires.
   ========================================================================== */

@media only screen and (min-width: 35em) {
  /* Style adjustments for viewports that meet the condition */
}

@media print,
  (-webkit-min-device-pixel-ratio: 1.25),
  (min-resolution: 1.25dppx),
  (min-resolution: 120dpi) {
  /* Style adjustments for high resolution devices */
}

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   https://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */

@media print {
  *,
  *::before,
  *::after {
    background: #fff !important;
    color: #000 !important;
    /* Black prints faster */
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]::after {
    content: " (" attr(href) ")";
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  /*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */
  a[href^="#"]::after,
  a[href^="javascript:"]::after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  /*
   * Printing Tables:
   * https://web.archive.org/web/20180815150934/http://css-discuss.incutio.com/wiki/Printing_Tables
   */
  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}

a {
  color: var(--french-gray);
  text-decoration-thickness: 0.1em;
  text-decoration-color: var(--cadet-gray);

  &:hover {
    color: white;
    text-decoration-thickness: 0.2em;
    text-decoration-color: var(--lapis-lazuli);
  }

  &:visited {
    color: var(--cadet-gray);
  }
}
